<template>
  <div>
    <CRow>
  <CCol col="12" xl="12">
    <CCard>
      <CCardHeader class="">
      <CRow>
      <CCol col="6" xl="6">
        <h6 class="w-100 text-muted">Položky formulára </h6>
         <h2 class="mb-0 page-title" v-if="data">{{data.title}}</h2>
      </CCol>
      <CCol col="6" xl="6">
           <div class="text-right system-options w-100 d-block" style="">
            <router-link :to="'/form-item/' + id + '/add'"  class="nav-link"
                                                 :href="'/form-item/' + id + '/add'">
                                        <template>
                                            <a-button type="primary" >Nová položka</a-button>
                                        </template>
                                    </router-link>
          </div>
      </CCol>
      </CRow>
      </CCardHeader>
      <CCardBody>
                        <div class="table-responsive">

                            <div class="table-flush ant-table-wrapper">
                                <div class="ant-table-body">
                                    <table class="ant-table " style="width:100%">
                                        <thead class="ant-table-thead">
                                        <tr>
                                            <th scope="col" colspan="2" style="text-align:left" class="ant-table-column-has-actions ant-table-column-has-sorters">Menu položky</th>
                                            <th scope="col">Typ položky</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <draggable @change="updateOrder" v-model="data" tag="tbody" class="ant-table-tbody" handle=".handle">
                                            <tr v-for="item in data" :key="item.id" class="ant-table-row ant-table-row-level-0">
                                                <td scope="row" style="width:30px;"> <a class="handle"><i class="fas fa-arrows-alt"></i></a></td>
                                                <td>{{ item.title }}</td>
                                                <td>{{ types[item.type] }}</td>
                                                <td style="text-align: right;">

                                                        <router-link :to="'/form-item/' + id + '/' + item.id" ><a-button class="mr-2">Upraviť</a-button></router-link>
                                                        <a-popconfirm placement="topRight"
                                                                      @confirm="confirm(item.id)"
                                                                      @cancel="cancel"
                                                                      okText="Áno"
                                                                      cancelText="Nie">
                                                            <template slot="title">
                                                                <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                                                Položka bude trvalo odstránená z vašej stránky.
                                                            </template>
                                                            <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                                        </a-popconfirm>
                                                  </td>

                                            </tr>
                                        </draggable>
                                    </table>
                                  </div>
                                  </div>
                        </div>

                     </CCardBody>
                     </CCard>
                     </CCol>
                     </CRow>
    </div>
</template>
<script>
    import {dataService} from "../../_services";
    import {Popconfirm} from "ant-design-vue"

    import draggable from "vuedraggable";
    const columns = [
        {
            title: 'Názov menu',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'URL',
            dataIndex: 'url',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];

    export default {
        name: "formitems",
        props: ['id'],
        colorsto: JSON.parse(localStorage.colors),
        components: {
            "a-popconfirm": Popconfirm, draggable
        },
        data() {
            return {
                data: [],
                colorsto: JSON.parse(localStorage.colors),
                dragging: false,
                loading: false,
                types: ['Nedefinované','Text','Výberovník','Dlhý text','Celé číslo','Desatiné číslo','E-mail','Dátum','Ratings'],
                columns,
            };
        },
        mounted() {
            this.fetch();
        },
        methods: {
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            cancel() {
            },
            updateOrder()
            {
                dataService.axiosPost("reorder/formitems", this.data).then(results => {
                    if(results)
                    {
                        this.$message.success('Položky boli zoradené');
                    }

                })
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch(this.$options.name,this.id).then(results => {
                    this.data = results;
                    this.loading = false;
                });
            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }

    .handle i
    {
        color: #888888;
    }
    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination5-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination5-item.ant-pagination5-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination5-item-link.ant-pagination5-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
